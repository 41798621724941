/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import { AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import {
  ArrowR,
  BtnWrapper,
  ChatArea,
  ChatBoxContainer,
  ContinueBtn,
  DisclaimerWrap,
  FilledStarIcon,
  Header,
  InitialInputContainer,
  InputContainerChat,
  LinkStyle,
  MessageBubble,
  MessageRow,
  NextButton,
  OuterContainer,
  OutlinedStarIcon,
  SendButton,
  StarInCircleIcon,
  StyledTextField,
} from '../../styles/aiSubmit.styles';
import WorthyAPI from '../../services/worthyAPI';
import { useAppDispatch, useAppSelector, useMobileVersion } from '../../app/hooks';
import {
  getConversationMessages,
  getConversationSubmit,
  getConversationWaiting,
  selectSubmissionId,
  setConversationWaiting,
  interruptConversationSubmit,
  Message,
  getResponseStarted,
  setResponseStarted,
} from '../../slices/submitSlice';
import { subscribeToSocketEvents } from '../../services/socket';
import { StepProps } from './common';
import GA from '../../data/GA';

function NextButtonComponent({ onClick }: { onClick: () => void }) {
  const scrollToBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBtnRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <NextButton
      ref={scrollToBtnRef}
      disableElevation
      fullWidth
      type="submit"
      data-automation="ai-submit-next"
      variant="contained"
      size="large"
      color="highlight"
      onClick={onClick}
    >
      Next
    </NextButton>
  );
}

const MessageItem = React.memo(({ msg }: { msg: Message }) => {
  if (msg.role !== 'user') {
    return (
      <MessageRow role={msg.role} id="message-row">
        <MessageBubble assistant loading={false}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', whiteSpaceCollapse: 'collapse' }}>
            <StarInCircleIcon id="star" sx={{ mr: 1, mt: '12px' }} />
            <Typography variant="body1" component="span">
              <ReactMarkdown>{msg.content}</ReactMarkdown>
            </Typography>
          </Box>
        </MessageBubble>
      </MessageRow>
    );
  }
  return (
    <MessageRow role={msg.role} id="message-row">
      <MessageBubble assistant={false} loading={false}>
        <Typography variant="body1">{msg.content}</Typography>
      </MessageBubble>
    </MessageRow>
  );
});
MessageItem.displayName = 'MessageItem';

export default function Chat({ next }: StepProps) {
  const dispatch = useAppDispatch();
  const submissionId = useAppSelector(selectSubmissionId);
  const socketMessages = useAppSelector(getConversationMessages);
  const waiting = useAppSelector(getConversationWaiting);
  const submit = useAppSelector(getConversationSubmit);
  const isResponseStarted = useAppSelector(getResponseStarted);
  const isMobile = useMobileVersion();
  const [messages, setMessages] = useState<Message[]>(socketMessages);

  const [userInput, setUserInput] = useState('');
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState('');
  const [chatStarted, setChatStarted] = useState(false);
  const [animationStopped, setAnimationStopped] = useState(false);
  const dummyRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<HTMLInputElement>(null);
  const [focusCalled, setFocusCalled] = useState(false);

  useEffect(() => {
    setMessages(socketMessages);
  }, [socketMessages]);

  useEffect(() => {
    subscribeToSocketEvents(submissionId, dispatch);
  }, [submissionId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dummyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);

    return () => clearTimeout(timer);
  }, [socketMessages, submit, waiting]);

  useEffect(() => {
    if (chatStarted || animationStopped) return;
    const texts = [
      'I have a 2 carat diamond ring. It’s a round-cut solitaire diamond.',
      'I’d like to sell my wedding ring. It has a yellow diamond in the center and 2 smaller diamonds on the side.',
      'I have a wedding set with a diamond engagement ring and matching wedding band.',
    ];
    let currentTextIndex = 0;
    let charIndex = 0;
    let timeoutId: number;

    const type = () => {
      if (chatStarted || animationStopped) return;
      const targetText = texts[currentTextIndex];
      if (charIndex <= targetText.length) {
        setAnimatedPlaceholder(targetText.substring(0, charIndex));
        charIndex++;
        timeoutId = window.setTimeout(type, 50);
      } else {
        timeoutId = window.setTimeout(erase, 2000);
      }
    };

    const erase = () => {
      if (chatStarted || animationStopped) return;
      const targetText = texts[currentTextIndex];
      if (charIndex > 0) {
        charIndex--;
        setAnimatedPlaceholder(targetText.substring(0, charIndex));
        timeoutId = window.setTimeout(erase, 20);
      } else {
        currentTextIndex = (currentTextIndex + 1) % texts.length;
        timeoutId = window.setTimeout(type, 500);
      }
    };

    type();
    return () => window.clearTimeout(timeoutId);
  }, [chatStarted, animationStopped]);

  // to prevent multiple sending message requests before dispatcher update waiting flag
  let localWaiting = false;
  const handleSend = async () => {
    if (!userInput.trim() || waiting || localWaiting) return;
    localWaiting = true;
    if (!chatStarted) {
      setChatStarted(true);
      setAnimationStopped(true);
    }
    if (submit) dispatch(interruptConversationSubmit());
    dispatch(setConversationWaiting({ waiting: true }));
    const userMsg = _.cloneDeep(userInput);
    setMessages((prev) => [...prev, { role: 'user', content: userMsg } as Message]);
    setUserInput('');
    await WorthyAPI.createNewMessage(userMsg, submissionId);
    dispatch(setResponseStarted({ responseStarted: true }));
    if (!isMobile) {
      setTimeout(() => {
        chatInputRef.current?.focus();
      }, 1000);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      await handleSend();
    }
  };

  const handleFocus = () => {
    setAnimationStopped(true);
    if (!focusCalled) {
      GA.aiFocus();
      setFocusCalled(true);
    }
  };

  const handleNext = () => {
    GA.chatNext();
    next({} as any);
  };

  return (
    <OuterContainer id="outer">
      <AnimatePresence mode="wait">
        {!chatStarted ? (
          <>
            <Header
              key="header"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Typography variant={isMobile ? 'h2Mobile' : 'h2'}>
                Lets start selling{isMobile ? <br /> : ' '}your item!
              </Typography>
            </Header>
            <InitialInputContainer
              key="initialInput"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Typography
                variant="h5"
                sx={{
                  alignSelf: 'flex-start',
                  marginBottom: '8px',
                  fontWeight: 300,
                }}
              >
                Tell us about your item:
              </Typography>
              <StyledTextField
                animated={!animationStopped}
                initInput={true}
                multiline
                rows={6}
                value={userInput}
                placeholder={animationStopped ? 'Tell us about your item..' : animatedPlaceholder}
                onFocus={handleFocus}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </InitialInputContainer>
            <BtnWrapper
              key="btn"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <ContinueBtn
                disableElevation
                type="submit"
                onClick={handleSend}
                disabled={!userInput.length}
                variant="contained"
                size="large"
                color="highlight"
              >
                Continue
              </ContinueBtn>
            </BtnWrapper>
          </>
        ) : (
          <ChatBoxContainer
            key="chatBox"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <ChatArea id="chat-area">
              {messages.map((msg, idx) => (
                <MessageItem key={idx} msg={msg} />
              ))}
              {isResponseStarted && !submit && (
                <MessageRow role="assistant">
                  <MessageBubble assistant loading={isResponseStarted}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <StarInCircleIcon id="star" sx={{ mr: 1, mt: 2 }} />
                      <OutlinedStarIcon id="outlined-star" />
                    </Box>
                  </MessageBubble>
                </MessageRow>
              )}
              {submit && !waiting && <NextButtonComponent onClick={handleNext} />}
              <div ref={dummyRef} />
            </ChatArea>
            <InputContainerChat>
              <StyledTextField
                inputRef={chatInputRef}
                animated={!animationStopped}
                initInput={false}
                multiline
                minRows={1}
                maxRows={6}
                value={userInput}
                placeholder="Tell me about your item:"
                inputProps={{ style: { paddingLeft: '10px' } }}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <SendButton onClick={handleSend}>
                <ArrowR color="inherit" />
              </SendButton>
            </InputContainerChat>
          </ChatBoxContainer>
        )}
      </AnimatePresence>
      {!chatStarted && (
        <DisclaimerWrap>
          <Typography variant="body1" color="primary.main" fontSize="10px">
            AI Chat Disclaimer:
          </Typography>
          <Typography variant="body1" color="secondary.main" fontSize="10px">
            This AI chat may contain errors and is not intended to create any legal or contractual
            obligations. For accurate information, please refer to the company&apos;s official
            terms. By engaging in this conversation, you agree to the company&apos;s&nbsp;
            <LinkStyle
              href={`${process.env.REACT_APP_SITE_URL}/about/privacy-policy`}
              target="_blank"
            >
              [Privacy Policy]
            </LinkStyle>
            &nbsp;and&nbsp;
            <LinkStyle
              href={`${process.env.REACT_APP_SITE_URL}/about/terms-and-conditions`}
              target="_blank"
            >
              [Terms & Conditions]
            </LinkStyle>
            .
          </Typography>
        </DisclaimerWrap>
      )}
    </OuterContainer>
  );
}
